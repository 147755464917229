document.addEventListener("DOMContentLoaded", function () {
  (function () {
    $(".Consultations").ready(function () {
      $(".Consultations .schedule-call, .reschedule-call, .cancel-call").on(
        "click",
        function () {
          $(".ConsultationTable").showSpinner();
          var calendlyURL = $(this).data("calendly-url");
          Calendly.showPopupWidget(calendlyURL, function () {
            location.reload();
          });
          return false;
        }
      );
    });
  })();
});
