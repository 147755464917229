// prevent page reloads when clicking links with '#' href Turbolinks issue: https://github.com/turbolinks/turbolinks/issues/75
document.addEventListener("DOMContentLoaded", function() {
  window.Turbolinks.Controller.prototype.nodeIsVisitableOld = window.Turbolinks.Controller.prototype.nodeIsVisitable;
  window.Turbolinks.Controller.prototype.nodeIsVisitable = function (elem) {

    var href = elem.getAttribute('href') || '';
    var hrefIsAnchor = href === "#";

    return !hrefIsAnchor && window.Turbolinks.Controller.prototype.nodeIsVisitableOld(elem);
  };
});