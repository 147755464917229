document.addEventListener("DOMContentLoaded", function () {
  const footer = document.getElementsByClassName("sc-Actions")[0];
  const content = document.querySelector(
    ".mobileWeb .sc-SiteLayout-main .sc-SectionLayout"
  );
  const mobileWeb = document.getElementsByClassName("mobileWeb")[0];
  const adaBox = document.getElementsByClassName("ada-button-frame")[0];

  if (footer && content) {
    const footerHeight = footer.offsetHeight;
    content.style.paddingBottom = footerHeight + "px";

    window.addEventListener("resize", function () {
      const inputs = document.querySelectorAll("input, textarea");
      if (inputs.length > 0) {
        window.scrollBy(0, 100);
      }
    });
  }

  if (mobileWeb && footer && adaBox) {
    const adjustIframeStyle = function () {
      const footerHeight = footer.offsetHeight;
      adaBox.style.bottom = footerHeight + "px";
    };

    setTimeout(adjustIframeStyle, 100);
  }
});
