(function() {
  $.fn.returnsGraph = function(action, options) {

    if (action === 'create') {
      var graph = new ReturnsGraph(_.extend(options, {$el: this}));
      this.data('graph', graph);

      graph.create();
    } else if (action === 'update') {
      var graph = this.data('graph');
      graph.update(options);
    } else if (action === 'waiting') {
      var graph = this.data('graph');

      graph.waiting();
    }

    return this;
  };

  function ReturnsGraph(options) {
    var DEFAULTS = {
      margin: {
        top: 10,
        right: 10,
        left: 60,
        bottom: 30
      },
      graphHeight: 375,
      tooltipClass: '.ReturnsGraph-tooltip',
      transitionDuration: 800,
      transitionEasing: 'elastic'
    };

    this.settings = $.extend(DEFAULTS, options);
    this.settings.$parentEl = this.settings.$el.parent();
    this.settings.width = this.settings.$el.width() - this.settings.margin.left - this.settings.margin.right;
    this.settings.height = Math.min(0.75 * this.settings.width, this.settings.graphHeight) - this.settings.margin.top - this.settings.margin.bottom;
    this.settings.yTicks = 4;
  }

  _.extend(ReturnsGraph.prototype, {
    hasData: function() {
      return this.settings.lines.some(function(line) {
        return line.returnsByDate.length > 0;
      });
    },

    create: function() {
      this.parseData();
      this.setRangeValues();
      this.setUpScales();
      this.setUpGenerators();
      this.createLayout();
      this.drawLines();
      this.initHover();

      if (!this.hasData()) {
        this.showZeroState();
      }
    },

    update: function(options) {
      this.settings.lines = options.lines;
      if (this.hasData()) {
        this.clearZeroState();
        this.parseData();
        this.setRangeValues();
        this.updateScales();
        this.updateAxis();
        this.updateLines();
        this.initHover();
      } else {
        this.showZeroState();
      }
    },

    showZeroState: function() {
      this.settings.$parentEl.find('.ReturnsGraph-zeroState').addClass('u-displayFlex');
    },

    clearZeroState: function() {
      this.settings.$parentEl.find('.ReturnsGraph-zeroState').removeClass('u-displayFlex');
    },

    waiting: function() {
      this.updateZeroLines();
    },

    parseData: function() {
      _.each(this.settings.lines, function(line) {
        _.each(line.returnsByDate, function(ret) {
          ret.date = new Date(ret.date);
          ret.value = parseFloat(ret.value);
        });
      });
    },

    setRangeValues: function() {
      this.ranges = _.reduce(this.settings.lines, function(memo, line) {
        _.each(line.returnsByDate, function(ret) {
          if (_.isUndefined(memo.minReturn) || ret.value < memo.minReturn) {
            memo.minReturn = ret.value;
          }
          if (_.isUndefined(memo.maxReturn) || ret.value > memo.maxReturn) {
            memo.maxReturn = ret.value;
          }
        });
        var lineDays = _.map(line.returnsByDate, function(ret) { return ret.date.getTime(); });
        memo.uniqueDates = _.union(lineDays, memo.uniqueDates);
        return memo;
      }, {uniqueDates: []});

      this.ranges.verticalBuffer = Math.abs(this.ranges.maxReturn - this.ranges.minReturn) / 6;
      this.ranges.uniqueDates = _.sortBy(this.ranges.uniqueDates, function(date) { return date; });
      this.ranges.minDate = new Date(_.first(this.ranges.uniqueDates));
      this.ranges.maxDate = new Date(_.last(this.ranges.uniqueDates));
    },

    setUpScales: function() {
      var yDomainValues = [this.ranges.minReturn - this.ranges.verticalBuffer, this.ranges.maxReturn + this.ranges.verticalBuffer];

      yDomainValues = d3.extent(yTickValues(this.ranges.minReturn, this.ranges.maxReturn, this.settings.yTicks));

      this.scales = {
        x: d3.time.scale().domain([this.ranges.minDate, this.ranges.maxDate]).range([0, this.settings.width]),
        y: d3.scale.linear().domain(yDomainValues).range([this.settings.height, 0]).nice()
      };
    },

    updateScales: function() {
      this.scales.x.domain([this.ranges.minDate, this.ranges.maxDate]);
      this.scales.y.domain([this.ranges.minReturn - this.ranges.verticalBuffer, this.ranges.maxReturn + this.ranges.verticalBuffer]);
    },

    setUpGenerators: function() {
      var scales = this.scales;
      this.generators = {
        xAxis: d3.svg.axis().scale(scales.x).tickSize(10, 0).tickValues( this.scales.x.domain() ).tickPadding(5).orient('bottom')
          .tickFormat(function(date) {
            return d3.time.format('%b %e, %Y')(date)
          }),
        yAxis: d3.svg.axis().scale(scales.y).tickSize(-1 * this.settings.width, 0).tickValues(yTickValues(this.ranges.minReturn, this.ranges.maxReturn, this.settings.yTicks)).tickPadding(5)
          .tickFormat(function(d) {
            return percentFormat(d);
          }).orient('left'),
        line: d3.svg.line()
          .x(function(d) { return scales.x(d.date); })
          .y(function(d) { return scales.y(d.value); }),
        zero: d3.svg.line()
          .x(function(d) { return scales.x(d.date); })
          .y(function(d) { return scales.y(0); })
      };
    },

    createLayout: function() {
      this.svg = d3.select('#' + this.settings.$el.attr('id'))
        .attr('width', this.settings.width + this.settings.margin.left + this.settings.margin.right)
        .attr('height', this.settings.height + this.settings.margin.top + this.settings.margin.bottom)
        .on('mousemove', function() { this.updateHover(); }.bind(this))
        .on('mouseleave', function() { this.hideHover(); }.bind(this))
        .append('g')
        .attr('transform', 'translate(' + this.settings.margin.left + ',' + this.settings.margin.top + ')');

      this.svg.append('g')
        .attr('class', 'ReturnsGraph-axis--x ReturnsGraph-axis')
        .attr('transform', 'translate(0,' + this.settings.height + ')')
        .call(this.generators.xAxis)
        .selectAll('text').style('text-anchor', function(tick, idx) { return idx % 2 === 0 ? 'start' : 'end' });

      this.svg.append('g')
        .attr('class', 'ReturnsGraph-axis--y ReturnsGraph-axis')
        .attr('transform', 'translate(0, 0)')
        .call(this.generators.yAxis);

      this.svg.append('path')
        .attr('class', 'ReturnsGraph-hoverable ReturnsGraph-bar');

      this.svg.selectAll('g.tick').attr('class', 'tick ReturnsGraph-axisTick ReturnsGraph-label');

      this.svg.selectAll('g .ReturnsGraph-axis--y .tick').style('stroke-dasharray', function(tickLine, idx) { return parseInt(tickLine) === 0 ? 'none' : '5' });
    },

    updateAxis: function() {
      d3.select('.ReturnsGraph-axis--y.ReturnsGraph-axis').transition().ease(this.settings.transitionEasing).duration(this.settings.transitionDuration).call(this.generators.yAxis);
      d3.select('.ReturnsGraph-axis--x.ReturnsGraph-axis')
        .transition()
        .ease(this.settings.transitionEasing)
        .duration(this.settings.transitionDuration)
        .call(this.generators.xAxis)
        .selectAll('text')
        .style('text-anchor', 'start');

      this.svg.selectAll('g.tick').attr('class', 'tick ReturnsGraph-axisTick ReturnsGraph-label');
    },

    drawLines: function() {
      _.each(this.settings.lines, function(singleLine) {
        this.appendLine(singleLine);
      }.bind(this));
      // make sure all of the svg circles are drawn after (on top of the paths)
      _.each(this.settings.lines, function(singleLine) {
        this.appendCircle(singleLine);
        this.updateLine(singleLine);
      }.bind(this));
    },

    initHover: function() {
      this.updateHover(this.settings.width);
      this.hideHover();
    },

    updateHoverBar: function(date) {
      d3.select('#' + this.settings.$el.attr('id') + ' .ReturnsGraph-hoverable.ReturnsGraph-bar')
        .classed('ReturnsGraph-hoverable--show', true)
        .attr('d', 'M'+ this.scales.x(date) + ',' + this.settings.height + 'L'+ this.scales.x(date) + ',' + 0);
    },

    updateCirclesTooltipValue: function(activePoints) {
      $(this.settings.tooltipClass).find('li').remove();
      d3.selectAll('.ReturnsGraph-hoverable.ReturnsGraph-circle').classed('ReturnsGraph-hoverable--show', false);
      _.each(activePoints.lines, function(point) {
        if (point) {
          var circle = d3.select('[data-name="' + point.dataSeriesName + '"].ReturnsGraph-circle'),
            legend = '<span class="ReturnsGraph-tooltipLegend ' + point.color_class + '"></span>';

          circle
            .attr('class', 'ReturnsGraph-hoverable ReturnsGraph-hoverable--show ReturnsGraph-circle ' + point.color_class)
            .attr('cx', this.scales.x(activePoints.date))
            .attr('cy', this.scales.y(point.value));

          $(this.settings.tooltipClass).find('ul').append('<li>' + legend + '<span class="ReturnsGraph-tooltipName">' + point.displayName + '</span><span class="ReturnsGraph-tooltipValue">' + percentFormat(point.value) + '</span></li>');
        }
      }.bind(this));
    },

    updateTooltip: function(options) {
      $(this.settings.tooltipClass).find('.ReturnsGraph-tooltipDate').text(dateFormat(options.date));
      $(this.settings.tooltipClass).css({
        'left' : (this.scales.x(options.date) + options.leftMargin) - (options.offset > 0 ? options.toolWidth - 1 : 1)
      }).addClass('ReturnsGraph-hoverable--show');
    },

    updateHover: function(mouseX) {
      if (typeof mouseX === 'undefined') {
        var container = d3.select('#' + this.settings.$el.attr('id'));
        mouseX = d3.mouse(container.node())[0] - this.settings.margin.left;
      }

      if(mouseX >= 0 && mouseX <= this.settings.width) {
        var date = this.scales.x.invert(mouseX),
          toolWidth = $(this.settings.tooltipClass).outerWidth(),
          offset = mouseX - toolWidth,
          leftMargin = this.settings.margin.left,
          activePoints = this.getDataForDate(date, this.settings);
        if (activePoints.lines) {
          this.updateHoverBar(activePoints.date);
          this.updateTooltip({
            date: activePoints.date,
            leftMargin: leftMargin,
            offset: offset,
            toolWidth: toolWidth
          });
          this.updateCirclesTooltipValue(activePoints, this);
        }
      } else {
        this.hideHover();
      }
    },

    hideHover: function() {
      d3.selectAll('.ReturnsGraph-hoverable').classed('ReturnsGraph-hoverable--show', false);
    },

    appendLine: function(line) {
      this.svg.append('path')
        .datum(line.returnsByDate)
        .attr('class', 'ReturnsGraph-line ' + line.group + ' ' + line.color_class)
        .attr('data-name', line.dataSeriesName)
        .attr('d', this.generators.zero);
    },

    updateLine: function(line) {
      d3.select('[data-name="' + line.dataSeriesName + '"].ReturnsGraph-line')
        .datum(line.returnsByDate)
        .transition().duration(this.settings.transitionDuration)
        .attr('class', 'ReturnsGraph-line ' + line.group + ' ' + line.color_class)
        .attr('d', this.generators.line);
    },

    removeLineByDataSeriesName: function(dataSeriesName) {
      d3.select('[data-name="' + dataSeriesName + '"].ReturnsGraph-line')
        .transition().duration(this.settings.transitionDuration)
        .style('stroke-opacity', 0)
        .remove();
    },

    updateZeroLine: function(line) {
      d3.select('[data-name="' + line.dataSeriesName + '"].ReturnsGraph-line')
        .datum(line.returnsByDate)
        .transition().duration(this.settings.transitionDuration)
        .attr('d', this.generators.zero);
    },

    updateLines: function() {
      var updateLines = this.settings.lines,
        updateLineDataSeriesNames = _.map(updateLines, 'dataSeriesName');

      $('.ReturnsGraph-line').each(function(i, existingLine) {
        var existingLineDataSeriesName = $(existingLine).data().name;

        if (_.includes(updateLineDataSeriesNames, existingLineDataSeriesName)) { // if any existing line has new data, update it
          var newLine = _.first(_.filter(updateLines, { dataSeriesName: existingLineDataSeriesName }));
          this.updateLine(newLine);
          this.updateCircleColor(newLine);
          updateLineDataSeriesNames = _.reject(updateLineDataSeriesNames, function(dataSeriesName) { return dataSeriesName === existingLineDataSeriesName; });
        } else { // if an old line is not used, remove it
          this.removeLineByDataSeriesName(existingLineDataSeriesName);
        }
      }.bind(this));

      if (updateLineDataSeriesNames.length > 0) { // if an new line wasn't on the graph, draw it
        _.each(updateLineDataSeriesNames, function(lineDataSeriesName) {
          var newLine = _.first(_.filter(updateLines, { dataSeriesName: lineDataSeriesName }));
          this.appendLine(newLine);
          this.appendCircle(newLine);
          this.updateLine(newLine);
        }.bind(this));
      }
    },

    updateZeroLines: function() {
      _.each(this.settings.lines, function(singleLine) {
        this.updateZeroLine(singleLine);
      }.bind(this));
    },

    appendCircle: function(line) {
      this.svg.append('circle')
        .attr('r', 3.5)
        .attr('data-name', line.dataSeriesName)
        .attr('class', 'ReturnsGraph-hoverable ReturnsGraph-circle ' + line.color_class);
    },

    updateCircleColor: function(line) {
      d3.select('[data-name="' + line.dataSeriesName + '"].ReturnsGraph-circle')
        .attr('class', 'ReturnsGraph-hoverable ReturnsGraph-circle ' + line.color_class);
    },

    getDataForDate: function(date) {
      var bisect = d3.bisector(function(d) { return d; }).right,
          time = date.getTime(),
          index = bisect(this.ranges.uniqueDates, time),
          t0 = this.ranges.uniqueDates[index - 1],
          t1 = this.ranges.uniqueDates[index],
          closestDate = new Date(time - t0 > t1 - time ? t1 : t0);

      return {
        date: closestDate,
        lines: _.map(this.settings.lines, function(line) {
          var match = _.find(line.returnsByDate, function(ret) {
            return ret.date.getTime() === closestDate.getTime();
          });

          if (match) {
            return {
              displayName: line.displayName,
              dataSeriesName: line.dataSeriesName,
              group: line.group,
              color_class: line.color_class,
              value: match.value
            };
          }
        })
      };
    }
  });

  function dateFormat(date) {
    return d3.time.format('%B %d, %Y')(date);
  }

  function percentFormat(value) {
    if (Math.abs(value) < 1) {
      return d3.format('.1p')(value / 100);
    } else {
      return d3.format('.1%')(value / 100);
    }
  }

  function yTickValues(minReturn, maxReturn, yTickCount) {
    var buffer = Math.abs(maxReturn - minReturn) / 6;
    var range = (maxReturn + buffer) - (minReturn - buffer);
    var step = Math.ceil(range/yTickCount);
    var lowestPointInRange = Math.floor(minReturn/step)*step;

    var tickValues = [];
    for (var index = 0; index < yTickCount + 1; index +=1 ) {
      tickValues.push((index * step) + lowestPointInRange);
    }

    return tickValues;
  }
})();
