document.addEventListener("DOMContentLoaded", function () {
  (function () {
    $(".AppLogins--new").ready(function () {
      $.get("https://cdn.betterment.com/retail-status.json", function (data) {
        if (data.status === "PLANNED" && data.message) {
          $("[data-js-target='maintenance-message-dynamic-content']").text(
            data.message
          );
          $("[data-js-target='maintenance-message']").removeClass("u-hidden");
        }
      });
    });
  })();
});
