$.fn.asSignature = function() {
  var $signatureContainer = $(this),
      $signaturePad = $signatureContainer.find('.signature-pad').jSignature({ 'decor-color': 'transparent' }).append('<hr class="signature-line">'),
      $signatureInput = $($signatureContainer.data('signatureInputId'));

  $signatureContainer.change(function(){
    $signatureInput.val($signaturePad.jSignature('getData'));
  });

  $signatureInput.val('');

  $signatureContainer.find('.clear-signature').click(function() {
    $signaturePad.jSignature('clear');
    $signatureContainer.addClass('unsigned');
    $signatureInput.val('');
  });

  $signatureContainer.on('mousedown touchstart', function(e){
    var isSigned = $signaturePad.jSignature('getData', 'native').length > 0;
    $signatureContainer.toggleClass('unsigned', !isSigned);
  });
};
