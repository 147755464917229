(function () {
  var refreshForm = function (e) {
    var $form = $(this).closest('form'),
      attributes = $form.data('refresh') || {},
      $eventTarget = $(e.target),
      $spinnerTarget = attributes.spinner_target ? $(attributes.spinner_target) : $form,
      blurInput = $eventTarget.data('keep-active') !== true,
      keepSpinner = attributes.keep_spinner == true,
      url = attributes.path || $form.attr('action');

    if ($eventTarget.data('initial-value') === e.target.value) {
      return;
    }

    // check if the tab key (keycode 9) was used to get to an empty field
    if (e.type === 'keyup' && e.target.value === '' && e.which === 9) {
      return;
    }

    if (e.type === 'change' && e.target.type === 'text' && e.target.value !== '' && !$(e.target).hasClass('datepicker')) {
      return;
    }

    // masked inputs emit a change event when the value is formatted, causing a refresh loop
    if (e.isTrigger && $eventTarget.data('mask')) {
      return;
    }

    $spinnerTarget.showSpinner();
    if (blurInput) {
      $eventTarget.blur();
    }

    $form.data('pendingRequest', $.ajax({
      url: url,
      method: attributes.method || 'GET',
      data: $form.serialize(),
      dataType: 'script',
      beforeSend: function() {
        if ($form.data('pendingRequest')) {
          $form.data('pendingRequest').abort();
        }
      }
    }).always(function(xhr, text_status) {
      var $refreshedInput = $('#' + e.target.id);
      if (blurInput && $refreshedInput.is('[type=text], textarea')) {
        var length = $refreshedInput.val().length;
        $refreshedInput.focus().get(0).setSelectionRange(length, length);
      }
      if (text_status != "abort") {
        $form.removeData('pendingRequest');
        if (!keepSpinner)  {
          $spinnerTarget.hideSpinner();
        }
      }

      // Only available in style-closet-legacy:
      if (typeof $.fn.addAriaAttributes === "function") {
        $.fn.addAriaAttributes();
      }
    }));
  };

  $(document).on('change', '[data-behavior-refresh-form]', refreshForm);
  $(document).on('formRefresh', '[data-behavior-refresh-form]', refreshForm);
  $(document).on('keyup', '[data-behavior-refresh-form]', _.debounce(refreshForm, 1500));
}());
