if (this) {
  (function () {
    this.Calendly = {};
  }).call(this),
    (Calendly.domReady = function (e) {
      var t = !1,
        n = function () {
          document.addEventListener
            ? (document.removeEventListener("DOMContentLoaded", o),
              window.removeEventListener("load", o))
            : (document.detachEvent("onreadystatechange", o),
              window.detachEvent("onload", o));
        },
        o = function () {
          t ||
            (!document.addEventListener &&
              "load" !== event.type &&
              "complete" !== document.readyState) ||
            ((t = !0), n(), e());
        };
      if ("complete" === document.readyState) e();
      else if (document.addEventListener)
        document.addEventListener("DOMContentLoaded", o),
          window.addEventListener("load", o);
      else {
        document.attachEvent("onreadystatechange", o),
          window.attachEvent("onload", o);
        var i = !1;
        try {
          i = null == window.frameElement && document.documentElement;
        } catch (l) {}
        i &&
          i.doScroll &&
          !(function r() {
            if (!t) {
              try {
                i.doScroll("left");
              } catch (o) {
                return setTimeout(r, 50);
              }
              (t = !0), n(), e();
            }
          })();
      }
    }),
    function () {
      (Calendly.initInlineWidgets = function () {
        return Calendly.domReady(function () {
          return Calendly.createInlineWidgets();
        });
      }),
        (Calendly.initBadgeWidget = function (e) {
          return Calendly.domReady(function () {
            return Calendly.createBadgeWidget(e);
          });
        }),
        (Calendly.createInlineWidgets = function () {
          var e, t, n, o, i;
          for (
            t = document.querySelectorAll(".calendly-inline-widget"),
              i = [],
              n = 0,
              o = t.length;
            o > n;
            n++
          )
            (e = t[n]),
              e.getAttribute("data-processed")
                ? i.push(void 0)
                : (e.setAttribute("data-processed", !0),
                  i.push(new Calendly.Iframe(e, !0)));
          return i;
        }),
        (Calendly.createBadgeWidget = function (e) {
          return (
            this.destroyBadgeWiget(),
            (Calendly.badgeWidget = new Calendly.BadgeWidget({
              color: e.color,
              text: e.text,
              branding: e.branding,
              onClick: function () {
                return Calendly.showPopupWidget(e.url);
              },
            }))
          );
        }),
        (Calendly.destroyBadgeWiget = function () {
          return Calendly.badgeWidget
            ? (Calendly.badgeWidget.destroy(), delete Calendly.badgeWidget)
            : void 0;
        }),
        (Calendly.showPopupWidget = function (e, callback) {
          return (
            this.closePopupWidget(),
            (Calendly.popupWidget = new Calendly.PopupWidget(e, callback)),
            Calendly.popupWidget.show()
          );
        }),
        (Calendly.closePopupWidget = function () {
          return Calendly.popupWidget ? Calendly.popupWidget.close() : void 0;
        });
    }.call(this),
    function () {
      Calendly.Iframe = (function () {
        function e(e, t) {
          (this.parent = e),
            (this.inlineStyles = null != t ? t : !1),
            this.build(),
            this.inject();
        }
        return (
          (e.prototype.isMobile =
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )),
          (e.prototype.build = function () {
            return (
              (this.node = document.createElement("iframe")),
              (this.node.src = this.getSource()),
              (this.node.width = "100%"),
              (this.node.height = "100%"),
              (this.node.frameBorder = "0")
            );
          }),
          (e.prototype.inject = function () {
            return (
              this.format(),
              this.parent.appendChild(this.buildSpinner()),
              this.parent.appendChild(this.node)
            );
          }),
          (e.prototype.getSource = function () {
            return this.parent.getAttribute("data-url");
          }),
          (e.prototype.format = function () {
            return this.isMobile ? this.formatMobile() : this.formatDesktop();
          }),
          (e.prototype.formatDesktop = function () {
            return this.inlineStyles
              ? this.parent.setAttribute(
                  "style",
                  "position: relative;" + this.parent.getAttribute("style")
                )
              : void 0;
          }),
          (e.prototype.formatMobile = function () {
            return this.inlineStyles
              ? this.parent.setAttribute(
                  "style",
                  "position: relative;overflow-y:auto;-webkit-overflow-scrolling:touch;" +
                    this.parent.getAttribute("style")
                )
              : (this.parent.className += " mobile");
          }),
          (e.prototype.buildSpinner = function () {
            var e;
            return (
              (e = document.createElement("div")),
              (e.className = "spinner"),
              e.appendChild(this.buildBounce(1)),
              e.appendChild(this.buildBounce(2)),
              e.appendChild(this.buildBounce(3)),
              e
            );
          }),
          (e.prototype.buildBounce = function (e) {
            var t;
            return (
              (t = document.createElement("div")),
              (t.className = "bounce" + e),
              t
            );
          }),
          e
        );
      })();
    }.call(this),
    function () {
      var e = function (e, t) {
        return function () {
          return e.apply(t, arguments);
        };
      };
      Calendly.PopupWidget = (function () {
        function t(t, n) {
          (this.url = t),
            (this.onClose = n),
            (this.close = e(this.close, this)),
            (this.pageRoot = document.getElementsByTagName("html")[0]);
        }
        return (
          (t.prototype.show = function () {
            return (
              this.buildOverlay(), this.insertOverlay(), this.lockPageScroll()
            );
          }),
          (t.prototype.close = function () {
            return (
              this.destroyOverlay(), this.onClose(), this.unlockPageScroll()
            );
          }),
          (t.prototype.buildOverlay = function () {
            return (
              (this.overlay = document.createElement("div")),
              (this.overlay.className = "calendly-overlay"),
              this.overlay.appendChild(this.buildCloseOverlay()),
              this.overlay.appendChild(this.buildPopup())
            );
          }),
          (t.prototype.insertOverlay = function () {
            return document.body.insertBefore(
              this.overlay,
              document.body.firstChild
            );
          }),
          (t.prototype.buildCloseOverlay = function () {
            var e;
            return (
              (e = document.createElement("div")),
              (e.className = "calendly-close-overlay"),
              (e.onclick = this.close),
              e
            );
          }),
          (t.prototype.buildPopup = function () {
            var e;
            return (
              (e = document.createElement("div")),
              (e.className = "calendly-popup"),
              e.appendChild(this.buildPopupContent()),
              e.appendChild(this.buildCloseButton()),
              e
            );
          }),
          (t.prototype.buildPopupContent = function () {
            var e;
            return (
              (e = document.createElement("div")),
              (e.className = "calendly-popup-content"),
              e.setAttribute("data-url", this.url),
              new Calendly.Iframe(e),
              e
            );
          }),
          (t.prototype.buildCloseButton = function () {
            var e;
            return (
              (e = document.createElement("div")),
              (e.className = "calendly-popup-close"),
              (e.onclick = this.close),
              e
            );
          }),
          (t.prototype.destroyOverlay = function () {
            return this.overlay.parentNode.removeChild(this.overlay);
          }),
          (t.prototype.lockPageScroll = function () {
            return (this.pageRoot.className += " calendly-page-scroll-locked");
          }),
          (t.prototype.unlockPageScroll = function () {
            return (this.pageRoot.className = this.pageRoot.className.replace(
              " calendly-page-scroll-locked",
              ""
            ));
          }),
          t
        );
      })();
    }.call(this),
    function () {
      Calendly.BadgeWidget = (function () {
        function e(e) {
          (this.options = e), this.buildWidget(), this.insertWidget();
        }
        return (
          (e.prototype.destroy = function () {
            return this.widget.parentNode.removeChild(this.widget);
          }),
          (e.prototype.buildWidget = function () {
            return (
              (this.widget = document.createElement("div")),
              (this.widget.className = "calendly-badge-widget"),
              this.widget.appendChild(this.buildContent())
            );
          }),
          (e.prototype.insertWidget = function () {
            return document.body.insertBefore(
              this.widget,
              document.body.firstChild
            );
          }),
          (e.prototype.buildContent = function () {
            var e;
            return (
              (e = document.createElement("div")),
              (e.className = "calendly-badge-content"),
              "#ffffff" === this.options.color && (e.className += " white"),
              (e.onclick = this.options.onClick),
              (e.innerHTML = this.options.text),
              (e.style.background = this.options.color),
              this.options.branding && e.appendChild(this.buildBranding()),
              e
            );
          }),
          (e.prototype.buildBranding = function () {
            var e;
            return (
              (e = document.createElement("span")),
              (e.innerHTML = "powered by Calendly"),
              e
            );
          }),
          e
        );
      })();
    }.call(this),
    Calendly.initInlineWidgets();
}
