// FIXME: This component only exists in retail.
//        There used to be an implementation in style_closet, but it was removed:
//        https://github.com/Betterment/style-closet/pull/1256#issuecomment-630474260
//
//        We should look into replacing this with a non-jquery implementation
//        and upstream it into style_closet.

$.fn.signature = function () {
  var $signatureContainer = $(this);

  if ($signatureContainer.data("isSignaturePad")) {
    return;
  }

  $signatureContainer.data("isSignaturePad", true);

  var $signaturePad = $signatureContainer
      .find('[data-behavior="signature-pad"]')
      .jSignature?.({ "decor-color": "transparent" }),
    $signatureInput = $($signatureContainer.data("signatureInputId"));

  $signatureContainer.change(function () {
    $signatureInput.val($signaturePad.jSignature?.("getData"));
  });

  $signatureInput.val("");

  $signatureContainer
    .find('[data-behavior="clear-signature"]')
    .click(function () {
      $signaturePad.jSignature?.("clear");
      $signatureContainer.addClass("is-unsigned");
      $signatureInput.val("");
    });

  $signatureContainer.on("mousedown touchstart", function (e) {
    var isSigned = $signaturePad.jSignature?.("getData", "native").length > 0;
    $signatureContainer.toggleClass("is-unsigned", !isSigned);
  });
};

(function () {
  document.addEventListener("DOMContentLoaded", function () {
    $(document).on("turbolinks:load", function () {
      $('[data-behavior="signature-container"]').signature();
    });
  });
})();
