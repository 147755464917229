document.addEventListener("DOMContentLoaded", function () {
  (function () {
    $(".AppPerformanceBalances--show").ready(function () {
      $(".performance-balance form").on("change", function () {
        $("#balances_graph").balancesGraph("waiting");
      });

      $(".js-PerformanceBalanceForm form").on("change", function () {
        $("#balances_graph").balancesGraph("waiting");
      });
    });
  })();
});
