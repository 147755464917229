(function() {
  $.fn.autoFadeIn = function() {
    var duration = 400;

    $.each($('[data-behavior-auto-fade-in]'), function(_, el) {
      var element = $(el);
      var delay = element.data('behavior-auto-fade-in');

      if (delay) {
        setTimeout(function() {
          element.fadeIn(duration);
        }, delay);
      }
    });
  };
}());
